[
	{
		"leaderboard_id": 52,
		"image": "https://cdn.playbattleaces.com/images/icons/units/advancedblink.png",
		"Name": "Advanced Blink",
		"Type": "Ground",
		"Building": "Advanced Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 7000,
		"Speed": "8.26",
		"Range": 8,
		"Damage": 70,
		"Attack Rate": 0.85,
		"Multi1": -10,
		"Target1": "CoreExpansion",
		"Multi2": 20,
		"Target2": "BigGround",
		"Multi3": null,
		"Target3": "",
		"Ability": "Blink",
		"Splash": "",
		"Small": "",
		"Anti-Big": "Anti-Big",
		"Big": "Big",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "FAR HORIZON COLLECTIVE",
		"slug": "advancedblink",
		"emoji": ":AdvancedBlink:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/advancedblink.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/advancedblink.mp4",
		"website": "https://www.playbattleaces.com/units/advancedblink"
	},
	{
		"leaderboard_id": 85,
		"image": "https://cdn.playbattleaces.com/images/icons/units/advanceddestroyer.png",
		"Name": "Advanced Destroyer",
		"Type": "Ground",
		"Building": "Advanced Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 600,
		"Speed": "5,24",
		"Range": 23,
		"Damage": 700,
		"Attack Rate": 3,
		"Multi1": -2,
		"Target1": "CoreExpansion",
		"Multi2": 5.5,
		"Target2": "BigGround",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "Anti-Big",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "advanceddestroyer",
		"emoji": ":AdvancedDestroyer:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/advanceddestroyer.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/advanceddestroyer.mp4",
		"website": "https://www.playbattleaces.com/units/advanceddestroyer"
	},
	{
		"leaderboard_id": 61,
		"image": "https://cdn.playbattleaces.com/images/icons/units/advancedrecall.png",
		"Name": "Advanced Recall",
		"Type": "Ground",
		"Building": "Starforge",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 1000,
		"Speed": "6.30",
		"Range": 10,
		"Damage": 115,
		"Attack Rate": 0.4,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Recall",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "GHOSTS OF VENUS",
		"slug": "advancedrecall",
		"emoji": ":AdvancedRecall:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/advancedrecall.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/advancedrecall.mp4",
		"website": "https://www.playbattleaces.com/units/advancedrecall"
	},
	{
		"leaderboard_id": 37,
		"image": "https://cdn.playbattleaces.com/images/icons/units/advancedbot.png",
		"Name": "Advancedbot",
		"Type": "Ground",
		"Building": "Advanced Foundry",
		"Matter": 25,
		"Energy": 25,
		"Supply": 1,
		"Health": 600,
		"Speed": "6.30",
		"Range": 10,
		"Damage": 50,
		"Attack Rate": 0.4,
		"Multi1": 4,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Overclock",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "SENKAISHU LIMITED",
		"slug": "advancedbot",
		"emoji": ":AdvancedBot:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/advancedbot.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/advancedbot.mp4",
		"website": "https://www.playbattleaces.com/units/advancedbot"
	},
	{
		"leaderboard_id": 72,
		"image": "https://cdn.playbattleaces.com/images/icons/units/airship.png",
		"Name": "Airship",
		"Type": "Air",
		"Building": "Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1600,
		"Speed": "9.44",
		"Range": 10,
		"Damage": 65,
		"Attack Rate": 0.85,
		"Multi1": 5.15,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "NORTH PERFORMANCE",
		"slug": "airship",
		"emoji": ":Airship:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/airship.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/airship.mp4",
		"website": "https://www.playbattleaces.com/units/airship"
	},
	{
		"leaderboard_id": 49,
		"image": "https://cdn.playbattleaces.com/images/icons/units/artillery.png",
		"Name": "Artillery",
		"Type": "Ground",
		"Building": "Advanced Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1400,
		"Speed": "6.30",
		"Range": 18,
		"Damage": 600,
		"Attack Rate": 2.8,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "artillery",
		"emoji": ":Artillery:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/artillery.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/artillery.mp4",
		"website": "https://www.playbattleaces.com/units/artillery"
	},
	{
		"leaderboard_id": 39,
		"image": "https://cdn.playbattleaces.com/images/icons/units/assaultbot.png",
		"Name": "Assaultbot",
		"Type": "Ground",
		"Building": "Advanced Foundry",
		"Matter": 50,
		"Energy": 0,
		"Supply": 1,
		"Health": 600,
		"Speed": "6.30",
		"Range": 10,
		"Damage": 80,
		"Attack Rate": 0.4,
		"Multi1": 0.2,
		"Target1": "Big",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Overclock",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "Anti-Big",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "SENKAISHU LIMITED",
		"slug": "assaultbot",
		"emoji": ":AssaultBot:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/assaultbot.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/assaultbot.mp4",
		"website": "https://www.playbattleaces.com/units/assaultbot"
	},
	{
		"leaderboard_id": 76,
		"image": "https://cdn.playbattleaces.com/images/icons/units/ballista.png",
		"Name": "Ballista",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1000,
		"Speed": "6.30",
		"Range": 10,
		"Damage": 350,
		"Attack Rate": 0.5,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "NORTH PERFORMANCE",
		"slug": "ballista",
		"emoji": ":Ballista:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/ballista.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/ballista.mp4",
		"website": "https://www.playbattleaces.com/units/ballista"
	},
	{
		"leaderboard_id": 24,
		"image": "https://cdn.playbattleaces.com/images/icons/units/beetle.png",
		"Name": "Beetle",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 1200,
		"Speed": "7.88",
		"Range": 10,
		"Damage": 80,
		"Attack Rate": 0.9,
		"Multi1": 3.125,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "IRON AND SONS",
		"slug": "beetle",
		"emoji": ":Beetle:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/beetle.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/beetle.mp4",
		"website": "https://www.playbattleaces.com/units/beetle"
	},
	{
		"leaderboard_id": 57,
		"image": "https://cdn.playbattleaces.com/images/icons/units/behemoth.png",
		"Name": "Behemoth",
		"Type": "Ground",
		"Building": "Advanced Foundry",
		"Matter": 250,
		"Energy": 0,
		"Supply": 5,
		"Health": 8500,
		"Speed": "8.26",
		"Range": 6,
		"Damage": 280,
		"Attack Rate": 0.7,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "behemoth",
		"emoji": ":Behemoth:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/behemoth.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/behemoth.mp4",
		"website": "https://www.playbattleaces.com/units/behemoth"
	},
	{
		"leaderboard_id": 42,
		"image": "https://cdn.playbattleaces.com/images/icons/units/blink.png",
		"Name": "Blink",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 100,
		"Energy": 0,
		"Supply": 2,
		"Health": 1200,
		"Speed": "8.26",
		"Range": 8,
		"Damage": 120,
		"Attack Rate": 1.1,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Blink",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "FAR HORIZON COLLECTIVE",
		"slug": "blink",
		"emoji": ":Blink:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/blink.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/blink.mp4",
		"website": "https://www.playbattleaces.com/units/blink"
	},
	{
		"leaderboard_id": 35,
		"image": "https://cdn.playbattleaces.com/images/icons/units/blinkhunter.png",
		"Name": "Blink Hunter",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 1000,
		"Speed": "7.88",
		"Range": 10,
		"Damage": 70,
		"Attack Rate": 1.25,
		"Multi1": 3,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Blink",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "FAR HORIZON COLLECTIVE",
		"slug": "blinkhunter",
		"emoji": ":BlinkHunter:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/blinkhunter.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/blinkhunter.mp4",
		"website": "https://www.playbattleaces.com/units/blinkhunter"
	},
	{
		"leaderboard_id": 8,
		"image": "https://cdn.playbattleaces.com/images/icons/units/bomber.png",
		"Name": "Bomber",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 1000,
		"Speed": "13.16",
		"Range": 0,
		"Damage": 250,
		"Attack Rate": 0,
		"Multi1": 1.6,
		"Target1": "Anti-Big",
		"Multi2": 0.8,
		"Target2": "Small",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "IRON AND SONS",
		"slug": "bomber",
		"emoji": ":Bomber:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/bomber.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/bomber.mp4",
		"website": "https://www.playbattleaces.com/units/bomber"
	},
	{
		"leaderboard_id": 47,
		"image": "https://cdn.playbattleaces.com/images/icons/units/bulwark.png",
		"Name": "Bulwark",
		"Type": "Air",
		"Building": "Advanced Starforge",
		"Matter": 250,
		"Energy": 250,
		"Supply": 10,
		"Health": 8200,
		"Speed": "5.24",
		"Range": 10,
		"Damage": 280,
		"Attack Rate": 0.5,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "NORTH PERFORMANCE",
		"slug": "bulwark",
		"emoji": ":Bulwark:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/bulwark.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/bulwark.mp4",
		"website": "https://www.playbattleaces.com/units/bulwark"
	},
	{
		"leaderboard_id": 73,
		"image": "https://cdn.playbattleaces.com/images/icons/units/butterfly.png",
		"Name": "Butterfly",
		"Type": "Air",
		"Building": "Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1200,
		"Speed": "8.26",
		"Range": 8,
		"Damage": 150,
		"Attack Rate": 0.45,
		"Multi1": -1.8,
		"Target1": "CoreExpansion",
		"Multi2": 3,
		"Target2": "BigGround",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "Anti-Big",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "NORTH PERFORMANCE",
		"slug": "butterfly",
		"emoji": ":Butterfly:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/butterfly.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/butterfly.mp4",
		"website": "https://www.playbattleaces.com/units/butterfly"
	},
	{
		"leaderboard_id": 75,
		"image": "https://cdn.playbattleaces.com/images/icons/units/crab.png",
		"Name": "Crab",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 100,
		"Energy": 0,
		"Supply": 2,
		"Health": 2200,
		"Speed": "9.44",
		"Range": 0,
		"Damage": 200,
		"Attack Rate": 1.25,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "NORTH PERFORMANCE",
		"slug": "crab",
		"emoji": ":Crab:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/crab.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/crab.mp4",
		"website": "https://www.playbattleaces.com/units/crab"
	},
	{
		"leaderboard_id": 84,
		"image": "https://cdn.playbattleaces.com/images/icons/units/crossbow.png",
		"Name": "Crossbow",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 100,
		"Energy": 100,
		"Supply": 2,
		"Health": 2200,
		"Speed": "7.88",
		"Range": 10,
		"Damage": 100,
		"Attack Rate": 0.65,
		"Multi1": 3.14,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "crossbow",
		"emoji": ":Crossbow:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/crossbow.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/crossbow.mp4",
		"website": "https://www.playbattleaces.com/units/crossbow"
	},
	{
		"leaderboard_id": 17,
		"image": "https://cdn.playbattleaces.com/images/icons/units/crusader.png",
		"Name": "Crusader",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 7500,
		"Speed": "8.26",
		"Range": 2,
		"Damage": 150,
		"Attack Rate": 0.65,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "",
		"Manufacturer": "IRON AND SONS",
		"slug": "crusader",
		"emoji": ":Crusader:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/crusader.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/crusader.mp4",
		"website": "https://www.playbattleaces.com/units/crusader"
	},
	{
		"leaderboard_id": 58,
		"image": "https://cdn.playbattleaces.com/images/icons/units/destroyer.png",
		"Name": "Destroyer",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1500,
		"Speed": "6.30",
		"Range": 12,
		"Damage": 700,
		"Attack Rate": 3,
		"Multi1": -2,
		"Target1": "CoreExpansion",
		"Multi2": 5.5,
		"Target2": "BigGround",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "Anti-Big",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "destroyer",
		"emoji": ":Destroyer:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/destroyer.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/destroyer.mp4",
		"website": "https://www.playbattleaces.com/units/destroyer"
	},
	{
		"leaderboard_id": 11,
		"image": "https://cdn.playbattleaces.com/images/icons/units/dragonfly.png",
		"Name": "Dragonfly",
		"Type": "Air",
		"Building": "Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 2200,
		"Speed": "11.20",
		"Range": 6,
		"Damage": 170,
		"Attack Rate": 0.75,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "CORONA CENTRAL SYSTEMS",
		"slug": "dragonfly",
		"emoji": ":Dragonfly:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/dragonfly.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/dragonfly.mp4",
		"website": "https://www.playbattleaces.com/units/dragonfly"
	},
	{
		"leaderboard_id": 13,
		"image": "https://cdn.playbattleaces.com/images/icons/units/falcon.png",
		"Name": "Falcon",
		"Type": "Air",
		"Building": "Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 3300,
		"Speed": "5.24",
		"Range": 10,
		"Damage": 220,
		"Attack Rate": 0.7,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "GHOSTS OF VENUS",
		"slug": "falcon",
		"emoji": ":Falcon:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/falcon.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/falcon.mp4",
		"website": "https://www.playbattleaces.com/units/falcon"
	},
	{
		"leaderboard_id": 53,
		"image": "https://cdn.playbattleaces.com/images/icons/units/flakturret.png",
		"Name": "Flak Turret",
		"Type": "Base Defense",
		"Building": "Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 5000,
		"Speed": "0.00",
		"Range": 17,
		"Damage": 1000,
		"Attack Rate": 0.9,
		"Multi1": 5.5,
		"Target1": "BigAir",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Destruct",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "IRON AND SONS",
		"slug": "flakturret",
		"emoji": ":FlakTurret:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/flakturret.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/flakturret.mp4",
		"website": "https://www.playbattleaces.com/units/flakturret"
	},
	{
		"leaderboard_id": 86,
		"image": "https://cdn.playbattleaces.com/images/icons/units/gargantua.png",
		"Name": "Gargantua",
		"Type": "Ground",
		"Building": "Advanced Foundry",
		"Matter": 250,
		"Energy": 250,
		"Supply": 10,
		"Health": 13000,
		"Speed": "7.88",
		"Range": 8,
		"Damage": 500,
		"Attack Rate": 0.4,
		"Multi1": -0.64,
		"Target1": "Butterfly",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "gargantua",
		"emoji": ":AdvancedDestroyer:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/gargantua.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/gargantua.mp4",
		"website": "https://www.playbattleaces.com/units/gargantua"
	},
	{
		"leaderboard_id": 1,
		"image": "https://cdn.playbattleaces.com/images/icons/units/gunbot.png",
		"Name": "Gunbot",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 50,
		"Energy": 0,
		"Supply": 2,
		"Health": 600,
		"Speed": "6.30",
		"Range": 10,
		"Damage": 80,
		"Attack Rate": 0.7,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Overclock",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "SENKAISHU LIMITED",
		"slug": "gunbot",
		"emoji": ":GunBot:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/gunbot.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/gunbot.mp4",
		"website": "https://www.playbattleaces.com/units/gunbot"
	},
	{
		"leaderboard_id": 77,
		"image": "https://cdn.playbattleaces.com/images/icons/units/heavyballista.png",
		"Name": "Heavy Ballista",
		"Type": "Ground",
		"Building": "Advanced Foundry",
		"Matter": 250,
		"Energy": 250,
		"Supply": 10,
		"Health": 5000,
		"Speed": "6.30",
		"Range": 10,
		"Damage": 600,
		"Attack Rate": 0.7,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "",
		"Manufacturer": "NORTH PERFORMANCE",
		"slug": "heavyballista",
		"emoji": ":HeavyBallista:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/heavyballista.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/heavyballista.mp4",
		"website": "https://www.playbattleaces.com/units/heavyballista"
	},
	{
		"leaderboard_id": 38,
		"image": "https://cdn.playbattleaces.com/images/icons/units/heavyhunter.png",
		"Name": "Heavy Hunter",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 2500,
		"Speed": "9.44",
		"Range": 14,
		"Damage": 80,
		"Attack Rate": 1.25,
		"Multi1": 5.25,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "SENKAISHU LIMITED",
		"slug": "heavyhunter",
		"emoji": ":HeavyHunter:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/heavyhunter.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/heavyhunter.mp4",
		"website": "https://www.playbattleaces.com/units/heavyhunter"
	},
	{
		"leaderboard_id": 25,
		"image": "https://cdn.playbattleaces.com/images/icons/units/hornet.png",
		"Name": "Hornet",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 650,
		"Speed": "13.16",
		"Range": 10,
		"Damage": 15,
		"Attack Rate": 0.2,
		"Multi1": 4.33,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "CORONA CENTRAL SYSTEMS",
		"slug": "hornet",
		"emoji": ":Hornet:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/hornet.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/hornet.mp4",
		"website": "https://www.playbattleaces.com/units/hornet"
	},
	{
		"leaderboard_id": 74,
		"image": "https://cdn.playbattleaces.com/images/icons/units/hunter.png",
		"Name": "Hunter",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 1000,
		"Speed": "7.88",
		"Range": 10,
		"Damage": 90,
		"Attack Rate": 1.65,
		"Multi1": 2.72,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "NORTH PERFORMANCE",
		"slug": "hunter",
		"emoji": ":Hunter:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/hunter.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/hunter.mp4",
		"website": "https://www.playbattleaces.com/units/hunter"
	},
	{
		"leaderboard_id": 29,
		"image": "https://cdn.playbattleaces.com/images/icons/units/katbus.png",
		"Name": "Katbus",
		"Type": "Air",
		"Building": "Advanced Starforge",
		"Matter": 250,
		"Energy": 250,
		"Supply": 10,
		"Health": 15000,
		"Speed": "7.88",
		"Range": 8,
		"Damage": 150,
		"Attack Rate": 0.25,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "",
		"Manufacturer": "CORONA CENTRAL SYSTEMS",
		"slug": "katbus",
		"emoji": ":Katbus:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/katbus.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/katbus.mp4",
		"website": "https://www.playbattleaces.com/units/katbus"
	},
	{
		"leaderboard_id": 80,
		"image": "https://cdn.playbattleaces.com/images/icons/units/kingcrab.png",
		"Name": "King Crab",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 4000,
		"Speed": "9.44",
		"Range": 2,
		"Damage": 280,
		"Attack Rate": 0.5,
		"Multi1": -0.28,
		"Target1": "Worker",
		"Multi2": -0.5,
		"Target2": "BigGround",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "",
		"Manufacturer": "NORTH PERFORMANCE",
		"slug": "kingcrab",
		"emoji": ":KingCrab:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/kingcrab.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/kingcrab.mp4",
		"website": "https://www.playbattleaces.com/units/kingcrab"
	},
	{
		"leaderboard_id": 83,
		"image": "https://cdn.playbattleaces.com/images/icons/units/knight.png",
		"Name": "Knight",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 200,
		"Energy": 0,
		"Supply": 2,
		"Health": 2200,
		"Speed": "8.26",
		"Range": 6,
		"Damage": 180,
		"Attack Rate": 0.5,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "knight",
		"emoji": ":Knight:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/knight.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/knight.mp4",
		"website": "https://www.playbattleaces.com/units/knight"
	},
	{
		"leaderboard_id": 48,
		"image": "https://cdn.playbattleaces.com/images/icons/units/kraken.png",
		"Name": "Kraken",
		"Type": "Air",
		"Building": "Advanced Starforge",
		"Matter": 3000,
		"Energy": 3000,
		"Supply": 80,
		"Health": 160000,
		"Speed": "6.30",
		"Range": 12,
		"Damage": 1000,
		"Attack Rate": 0.35,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "FAR HORIZON COLLECTIVE",
		"slug": "kraken",
		"emoji": ":Kraken:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/kraken.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/kraken.mp4",
		"website": "https://www.playbattleaces.com/units/kraken"
	},
	{
		"leaderboard_id": 54,
		"image": "https://cdn.playbattleaces.com/images/icons/units/locust.png",
		"Name": "Locust",
		"Type": "Air",
		"Building": "Advanced Starforge",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 820,
		"Speed": "11.20",
		"Range": 6,
		"Damage": 160,
		"Attack Rate": 0.85,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "CORONA CENTRAL SYSTEMS",
		"slug": "locust",
		"emoji": ":Locust:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/locust.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/locust.mp4",
		"website": "https://www.playbattleaces.com/units/locust"
	},
	{
		"leaderboard_id": 51,
		"image": "https://cdn.playbattleaces.com/images/icons/units/mammoth.png",
		"Name": "Mammoth",
		"Type": "Ground",
		"Building": "Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 7500,
		"Speed": "8.26",
		"Range": 6,
		"Damage": 280,
		"Attack Rate": 1,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "Big",
		"Anti-Air": "",
		"Manufacturer": "IRON AND SONS",
		"slug": "mammoth",
		"emoji": ":Mammoth:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/mammoth.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/mammoth.mp4",
		"website": "https://www.playbattleaces.com/units/mammoth"
	},
	{
		"leaderboard_id": 6,
		"image": "https://cdn.playbattleaces.com/images/icons/units/missilebot.png",
		"Name": "Missilebot",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 1000,
		"Speed": "6.30",
		"Range": 10,
		"Damage": 90,
		"Attack Rate": 0.8,
		"Multi1": 2.75,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Overclock",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "SENKAISHU LIMITED",
		"slug": "missilebot",
		"emoji": ":MissileBot:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/missilebot.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/missilebot.mp4",
		"website": "https://www.playbattleaces.com/units/missilebot"
	},
	{
		"leaderboard_id": 9,
		"image": "https://cdn.playbattleaces.com/images/icons/units/mortar.png",
		"Name": "Mortar",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 2000,
		"Speed": "6.30",
		"Range": 22,
		"Damage": 600,
		"Attack Rate": 1.3,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Setup",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "mortar",
		"emoji": ":Mortar:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/mortar.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/mortar.mp4",
		"website": "https://www.playbattleaces.com/units/mortar"
	},
	{
		"leaderboard_id": 14,
		"image": "https://cdn.playbattleaces.com/images/icons/units/predator.png",
		"Name": "Predator",
		"Type": "Ground",
		"Building": "Advanced Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1250,
		"Speed": "8.26",
		"Range": 22,
		"Damage": 100,
		"Attack Rate": 2.6,
		"Multi1": 5,
		"Target1": "BigAir",
		"Multi2": 11,
		"Target2": "SmallAir",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "HEAVY UNION ALLIANCE",
		"slug": "predator",
		"emoji": ":Predator:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/predator.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/predator.mp4",
		"website": "https://www.playbattleaces.com/units/predator"
	},
	{
		"leaderboard_id": 28,
		"image": "https://cdn.playbattleaces.com/images/icons/units/raider.png",
		"Name": "Raider",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1300,
		"Speed": "8.29",
		"Range": 8,
		"Damage": 600,
		"Attack Rate": 0.4,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "IRON AND SONS",
		"slug": "raider",
		"emoji": ":Raider:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/raider.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/raider.mp4",
		"website": "https://www.playbattleaces.com/units/raider"
	},
	{
		"leaderboard_id": 34,
		"image": "https://cdn.playbattleaces.com/images/icons/units/recall.png",
		"Name": "Recall",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 100,
		"Energy": 0,
		"Supply": 2,
		"Health": 1200,
		"Speed": "6.30",
		"Range": 8,
		"Damage": 170,
		"Attack Rate": 1.15,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Recall",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "GHOSTS OF VENUS",
		"slug": "recall",
		"emoji": ":Recall:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/recall.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/recall.mp4",
		"website": "https://www.playbattleaces.com/units/recall"
	},
	{
		"leaderboard_id": 40,
		"image": "https://cdn.playbattleaces.com/images/icons/units/recallhunter.png",
		"Name": "Recall Hunter",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 50,
		"Energy": 50,
		"Supply": 2,
		"Health": 1000,
		"Speed": "5.24",
		"Range": 10,
		"Damage": 90,
		"Attack Rate": 1.25,
		"Multi1": 2.88,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Recall",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "GHOSTS OF VENUS",
		"slug": "recallhunter",
		"emoji": ":RecallHunter:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/recallhunter.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/recallhunter.mp4",
		"website": "https://www.playbattleaces.com/units/recallhunter"
	},
	{
		"leaderboard_id": 68,
		"image": "https://cdn.playbattleaces.com/images/icons/units/recallshocker.png",
		"Name": "Recall Shocker",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1800,
		"Speed": "4.00",
		"Range": 10,
		"Damage": 600,
		"Attack Rate": 3,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Recall",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "GHOSTS OF VENUS",
		"slug": "recallshocker",
		"emoji": ":RecallShocker:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/recallshocker.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/recallshocker.mp4",
		"website": "https://www.playbattleaces.com/units/recallshocker"
	},
	{
		"leaderboard_id": 27,
		"image": "https://cdn.playbattleaces.com/images/icons/units/scorpion.png",
		"Name": "Scorpion",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 50,
		"Energy": 0,
		"Supply": 2,
		"Health": 1350,
		"Speed": "9.44",
		"Range": 0,
		"Damage": 200,
		"Attack Rate": 1.25,
		"Multi1": null,
		"Target1": "",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "IRON AND SONS",
		"slug": "scorpion",
		"emoji": ":Scorpion:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/scorpion.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/scorpion.mp4",
		"website": "https://www.playbattleaces.com/units/scorpion"
	},
	{
		"leaderboard_id": 41,
		"image": "https://cdn.playbattleaces.com/images/icons/units/shocker.png",
		"Name": "Shocker",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1400,
		"Speed": "6.30",
		"Range": 10,
		"Damage": 600,
		"Attack Rate": 2.6,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "CORONA CENTRAL SYSTEMS",
		"slug": "shocker",
		"emoji": ":Shocker:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/shocker.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/shocker.mp4",
		"website": "https://www.playbattleaces.com/units/shocker"
	},
	{
		"leaderboard_id": 32,
		"image": "https://cdn.playbattleaces.com/images/icons/units/sniper.png",
		"Name": "Sniper",
		"Type": "Ground",
		"Building": "Advanced Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1000,
		"Speed": "5.24",
		"Range": 23,
		"Damage": 500,
		"Attack Rate": 1.8,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "SENKAISHU LIMITED",
		"slug": "sniper",
		"emoji": ":Sniper:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/sniper.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/sniper.mp4",
		"website": "https://www.playbattleaces.com/units/sniper"
	},
	{
		"leaderboard_id": 70,
		"image": "https://cdn.playbattleaces.com/images/icons/units/stinger.png",
		"Name": "Stinger",
		"Type": "Ground",
		"Building": "Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1600,
		"Speed": "13.16",
		"Range": 6,
		"Damage": 120,
		"Attack Rate": 0.25,
		"Multi1": -0.25,
		"Target1": "Expansion",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "IRON AND SONS",
		"slug": "stinger",
		"emoji": ":Stinger:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/stinger.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/stinger.mp4",
		"website": "https://www.playbattleaces.com/units/stinger"
	},
	{
		"leaderboard_id": 64,
		"image": "https://cdn.playbattleaces.com/images/icons/units/swiftshocker.png",
		"Name": "Swift Shocker",
		"Type": "Ground",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 1400,
		"Speed": "11.20",
		"Range": 8,
		"Damage": 105,
		"Attack Rate": 0.5,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "CORONA CENTRAL SYSTEMS",
		"slug": "swiftshocker",
		"emoji": ":SwiftShocker:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/swiftshocker.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/swiftshocker.mp4",
		"website": "https://www.playbattleaces.com/units/swiftshocker"
	},
	{
		"leaderboard_id": 20,
		"image": "https://cdn.playbattleaces.com/images/icons/units/turret.png",
		"Name": "Turret",
		"Type": "Base Defense",
		"Building": "Foundry",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 5000,
		"Speed": "0.00",
		"Range": 15,
		"Damage": 400,
		"Attack Rate": 0.9,
		"Multi1": -0.5,
		"Target1": "BigGround",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "Destruct",
		"Splash": "Splash",
		"Small": "",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "IRON AND SONS",
		"slug": "turret",
		"emoji": ":Turret:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/turret.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/turret.mp4",
		"website": "https://www.playbattleaces.com/units/turret"
	},
	{
		"leaderboard_id": 30,
		"image": "https://cdn.playbattleaces.com/images/icons/units/valkyrie.png",
		"Name": "Valkyrie",
		"Type": "Air",
		"Building": "Advanced Starforge",
		"Matter": 125,
		"Energy": 125,
		"Supply": 5,
		"Health": 2200,
		"Speed": "8.26",
		"Range": 10,
		"Damage": 65,
		"Attack Rate": 0.65,
		"Multi1": 11,
		"Target1": "Air",
		"Multi2": null,
		"Target2": "",
		"Multi3": null,
		"Target3": "",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "Anti-Air",
		"Manufacturer": "CORONA CENTRAL SYSTEMS",
		"slug": "valkyrie",
		"emoji": ":Valkyrie:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/valkyrie.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/valkyrie.mp4",
		"website": "https://www.playbattleaces.com/units/valkyrie"
	},
	{
		"leaderboard_id": 2,
		"image": "https://cdn.playbattleaces.com/images/icons/units/wasp.png",
		"Name": "Wasp",
		"Type": "Ground",
		"Building": "Core",
		"Matter": 25,
		"Energy": 0,
		"Supply": 2,
		"Health": 500,
		"Speed": "13.16",
		"Range": 0,
		"Damage": 40,
		"Attack Rate": 0.5,
		"Multi1": 0.375,
		"Target1": "Recall",
		"Multi2": -0.375,
		"Target2": "Expansion",
		"Multi3": -0.25,
		"Target3": "Worker",
		"Ability": "",
		"Splash": "",
		"Small": "Small",
		"Anti-Big": "",
		"Big": "",
		"Anti-Air": "",
		"Manufacturer": "GHOSTS OF VENUS",
		"slug": "wasp",
		"emoji": ":Wasp:",
		"videoTurnaround": "https://cdn.playbattleaces.com/videos/turnarounds/wasp.mp4",
		"videoGameplay": "https://cdn.playbattleaces.com/videos/gameplay/wasp.mp4",
		"website": "https://www.playbattleaces.com/units/wasp"
	}
]
{
  "leaderboardData1": [
    {
      "player": "GOStephano",
      "ranking": "10045",
      "units": "2, 35, 41, 13, 32, 29, 58, 51"
    },
    {
      "player": "trigger",
      "ranking": "10004",
      "units": "75, 35, 58, 73, 32, 30, 9, 51"
    },
    {
      "player": "͡° ͜ʖ°͡",
      "ranking": "9878",
      "units": "34, 40, 17, 11, 77, 29, 41, 61"
    },
    {
      "player": "aM TheoRy",
      "ranking": "9364",
      "units": "42, 35, 9, 11, 32, 29, 58, 51"
    },
    {
      "player": "Armeria",
      "ranking": "9173",
      "units": "2, 25, 9, 73, 57, 85, 37, 29"
    },
    {
      "player": "Clem",
      "ranking": "9159",
      "units": "2, 25, 9, 70, 32, 49, 58, 73"
    },
    {
      "player": "boanaan",
      "ranking": "9117",
      "units": "42, 35, 20, 13, 32, 29, 58, 53"
    },
    {
      "player": "망초",
      "ranking": "8864",
      "units": "83, 25, 68, 11, 77, 54, 58, 61"
    },
    {
      "player": "Night",
      "ranking": "8429",
      "units": "2, 25, 58, 70, 57, 54, 9, 51"
    },
    {
      "player": "Déca",
      "ranking": "8402",
      "units": "42, 35, 58, 73, 32, 14, 9, 51"
    },
    {
      "player": "MastermindRTS",
      "ranking": "8206",
      "units": "2, 25, 9, 73, 57, 29, 58, 70"
    },
    {
      "player": "LDK",
      "ranking": "8189",
      "units": "42, 35, 58, 11, 52, 30, 80, 51"
    },
    {
      "player": "tvoppa?",
      "ranking": "8155",
      "units": "42, 35, 9, 11, 32, 30, 58, 51"
    },
    {
      "player": "noob",
      "ranking": "8122",
      "units": "27, 25, 64, 70, 37, 54, 57, 13"
    },
    {
      "player": "ViBE",
      "ranking": "8076",
      "units": "2, 34, 41, 11, 86, 54, 17, 70"
    },
    {
      "player": "Aaa ♿ guy",
      "ranking": "8050",
      "units": "75, 35, 64, 70, 52, 29, 39, 30"
    },
    {
      "player": "-----noobs-below-this-line-----",
      "ranking": "8028",
      "units": "2, 25, 58, 11, 32, 29, 9, 70"
    },
    {
      "player": "BAT",
      "ranking": "7855",
      "units": "42, 35, 58, 51, 86, 30, 41, 72"
    },
    {
      "player": "imjustziggy",
      "ranking": "7817",
      "units": "1, 6, 9, 11, 37, 85, 17, 51"
    },
    {
      "player": "llllllllllll",
      "ranking": "7738",
      "units": "2, 25, 9, 73, 57, 29, 37, 85"
    },
    {
      "player": "fly",
      "ranking": "7727",
      "units": "42, 35, 20, 73, 86, 29, 58, 51"
    },
    {
      "player": "Sturgeon",
      "ranking": "7681",
      "units": "2, 35, 58, 13, 32, 54, 41, 51"
    },
    {
      "player": "StarAtt",
      "ranking": "7662",
      "units": "2, 25, 64, 72, 57, 54, 58, 70"
    },
    {
      "player": "GiantGrantGames",
      "ranking": "7657",
      "units": "1, 25, 9, 72, 39, 29, 52, 14"
    },
    {
      "player": "noob",
      "ranking": "7614",
      "units": "83, 84, 17, 13, 86, 49, 8, 61"
    },
    {
      "player": "State",
      "ranking": "7601",
      "units": "42, 35, 17, 11, 52, 29, 41, 51"
    },
    {
      "player": "jimmymicro",
      "ranking": "7586",
      "units": "2, 25, 58, 51, 32, 54, 41, 72"
    },
    {
      "player": "kng9976",
      "ranking": "7562",
      "units": "2, 25, 9, 51, 32, 54, 58, 11"
    },
    {
      "player": "printf",
      "ranking": "7538",
      "units": "27, 83, 38, 72, 32, 54, 68, 73"
    },
    {
      "player": "Jackster",
      "ranking": "7511",
      "units": "42, 35, 58, 11, 32, 29, 9, 51"
    },
    {
      "player": "SweetShotGames",
      "ranking": "7509",
      "units": "75, 35, 58, 61, 77, 85, 32, 14"
    },
    {
      "player": "Dewaltoss",
      "ranking": "7507",
      "units": "27, 83, 38, 72, 32, 29, 41, 13"
    },
    {
      "player": "Ninja Doggy",
      "ranking": "7440",
      "units": "83, 35, 9, 61, 39, 54, 86, 51"
    },
    {
      "player": "Erik",
      "ranking": "7376",
      "units": "2, 25, 64, 11, 39, 30, 80, 13"
    },
    {
      "player": "Flare",
      "ranking": "7356",
      "units": "42, 35, 58, 73, 32, 30, 20, 72"
    },
    {
      "player": "Nuggify",
      "ranking": "7352",
      "units": "42, 35, 9, 73, 52, 54, 20, 51"
    },
    {
      "player": "SticksBender",
      "ranking": "7347",
      "units": "1, 35, 9, 51, 37, 85, 58, 73"
    },
    {
      "player": "Adagramus",
      "ranking": "7343",
      "units": "42, 35, 17, 72, 77, 29, 68, 73"
    },
    {
      "player": "Fahrenheit",
      "ranking": "7321",
      "units": "2, 35, 41, 13, 39, 30, 58, 51"
    },
    {
      "player": "MrMcSmashy",
      "ranking": "7318",
      "units": "1, 25, 9, 72, 39, 29, 52, 14"
    },
    {
      "player": "TPABA_HE_OTPABA",
      "ranking": "7299",
      "units": "2, 25, 20, 73, 52, 49, 58, 14"
    },
    {
      "player": "stassin",
      "ranking": "7264",
      "units": "42, 35, 64, 73, 32, 54, 37, 51"
    },
    {
      "player": "chalupa batman mcArthur",
      "ranking": "7259",
      "units": "2, 25, 80, 51, 52, 54, 64, 73"
    },
    {
      "player": "PatsFan",
      "ranking": "7244",
      "units": "2, 25, 58, 13, 57, 29, 41, 30"
    },
    {
      "player": "Jaeyun",
      "ranking": "7228",
      "units": "2, 35, 9, 70, 32, 30, 58, 73"
    },
    {
      "player": "Pillars",
      "ranking": "7218",
      "units": "2, 25, 9, 70, 32, 29, 58, 73"
    },
    {
      "player": "Sojourn",
      "ranking": "7210",
      "units": "27, 83, 38, 13, 37, 29, 20, 51"
    },
    {
      "player": "Pokey26",
      "ranking": "7174",
      "units": "42, 35, 80, 13, 32, 29, 58, 30"
    },
    {
      "player": "Shade",
      "ranking": "7169",
      "units": "2, 25, 80, 11, 39, 85, 52, 51"
    },
    {
      "player": "Empress",
      "ranking": "7168",
      "units": "2, 25, 64, 70, 37, 49, 58, 85"
    },
    {
      "player": "RavinDrannor",
      "ranking": "7165",
      "units": "42, 35, 9, 51, 32, 30, 58, 73"
    },
    {
      "player": "msrm",
      "ranking": "7161",
      "units": "2, 25, 9, 70, 37, 85, 57, 11"
    },
    {
      "player": "Gator",
      "ranking": "7144",
      "units": "35, 2, 58, 13, 39, 30, 41, 51"
    },
    {
      "player": "jayxp123",
      "ranking": "7143",
      "units": "34, 35, 17, 73, 52, 29, 41, 51"
    },
    {
      "player": "Kosis",
      "ranking": "7137",
      "units": "1, 25, 20, 13, 39, 29, 52, 14"
    },
    {
      "player": "Atsou",
      "ranking": "7125",
      "units": "27, 25, 9, 51, 37, 29, 57, 14"
    },
    {
      "player": "Saliroz",
      "ranking": "7108",
      "units": "27, 35, 68, 13, 77, 54, 17, 51"
    },
    {
      "player": "aVa",
      "ranking": "7100",
      "units": "83, 35, 58, 61, 52, 29, 68, 11"
    },
    {
      "player": "Nicoract",
      "ranking": "7098",
      "units": "2, 35, 9, 51, 32, 30, 58, 72"
    },
    {
      "player": "A V",
      "ranking": "7097",
      "units": "34, 35, 20, 61, 32, 54, 58, 13"
    },
    {
      "player": "Skruffy",
      "ranking": "7090",
      "units": "1, 2, 38, 51, 32, 48, 9, 11"
    },
    {
      "player": "You",
      "ranking": "7090",
      "units": "75, 25, 20, 72, 57, 29, 58, 14"
    },
    {
      "player": "Conqueror",
      "ranking": "7081",
      "units": "27, 6, 58, 53, 86, 30, 9, 13"
    },
    {
      "player": "BILL",
      "ranking": "7072",
      "units": "2, 25, 58, 51, 57, 30, 64, 70"
    },
    {
      "player": "MadMan",
      "ranking": "7057",
      "units": "2, 25, 64, 70, 32, 54, 58, 73"
    },
    {
      "player": "Mangez",
      "ranking": "7057",
      "units": "34, 40, 68, 51, 32, 54, 58, 13"
    },
    {
      "player": "Trumpet",
      "ranking": "7053",
      "units": "34, 25, 68, 13, 86, 14, 58, 51"
    },
    {
      "player": "Bubbas",
      "ranking": "7041",
      "units": "34, 40, 68, 13, 52, 29, 17, 51"
    },
    {
      "player": "Heggman",
      "ranking": "7026",
      "units": "2, 25, 58, 51, 39, 29, 9, 11"
    },
    {
      "player": "drain",
      "ranking": "7019",
      "units": "75, 35, 80, 72, 77, 29, 58, 70"
    },
    {
      "player": "mogue",
      "ranking": "7018",
      "units": "27, 40, 68, 61, 57, 48, 58, 13"
    },
    {
      "player": "(SH)>Cellsidus",
      "ranking": "7018",
      "units": "75, 35, 80, 70, 77, 30, 58, 13"
    },
    {
      "player": "A Pine Tree",
      "ranking": "7017",
      "units": "2, 25, 9, 73, 57, 29, 58, 13"
    },
    {
      "player": "IsseN",
      "ranking": "7016",
      "units": "1, 6, 20, 51, 39, 29, 86, 11"
    },
    {
      "player": "Gorthauer",
      "ranking": "7015",
      "units": "2, 25, 9, 70, 32, 29, 58, 72"
    },
    {
      "player": "Jotak",
      "ranking": "7011",
      "units": "42, 35, 41, 72, 52, 48, 39, 73"
    },
    {
      "player": "Xpired",
      "ranking": "7000",
      "units": "42, 35, 9, 11, 32, 29, 58, 51"
    },
    {
      "player": "Hayman",
      "ranking": "7000",
      "units": "34, 40, 9, 11, 52, 30, 58, 51"
    },
    {
      "player": "zzz",
      "ranking": "7000",
      "units": "34, 40, 68, 61, 32, 54, 20, 73"
    },
    {
      "player": "IntuitioN",
      "ranking": "7000",
      "units": "42, 35, 80, 13, 52, 14, 58, 51"
    },
    {
      "player": "YTRog",
      "ranking": "7000",
      "units": "27, 40, 41, 13, 32, 54, 58, 72"
    }
  ],
  "leaderboardData2": [
    {
      "player": "aM TheoRy",
      "ranking": "8899",
      "units": "42, 35, 9, 11, 32, 29, 58, 51"
    },
    {
      "player": "-----noobs-below-this-line-----",
      "ranking": "8899",
      "units": "2, 25, 58, 11, 32, 29, 9, 70"
    },
    {
      "player": "Nuggify",
      "ranking": "8820",
      "units": "42, 35, 9, 73, 52, 54, 20, 51"
    },
    {
      "player": "mortar players = ♿",
      "ranking": "8607",
      "units": "2, 25, 58, 13, 32, 49, 38, 51"
    },
    {
      "player": "Night",
      "ranking": "8427",
      "units": "2, 25, 58, 70, 57, 54, 9, 51"
    },
    {
      "player": "<HBGS>ZaRDieNT",
      "ranking": "8290",
      "units": "42, 35, 41, 11, 57, 14, 58, 29"
    },
    {
      "player": "<HBGS>SickNess",
      "ranking": "8274",
      "units": "34, 6, 9, 53, 32, 14, 58, 11"
    },
    {
      "player": "Aaa ♿ guy",
      "ranking": "8032",
      "units": "75, 35, 64, 70, 52, 29, 39, 30"
    },
    {
      "player": "LDK",
      "ranking": "8020",
      "units": "42, 35, 58, 11, 52, 30, 80, 51"
    },
    {
      "player": "Skruffy",
      "ranking": "7917",
      "units": "1, 2, 38, 51, 32, 48, 9, 11"
    },
    {
      "player": "Jotak",
      "ranking": "7547",
      "units": "42, 35, 41, 72, 52, 48, 39, 73"
    },
    {
      "player": "♿ Smash ♿",
      "ranking": "7389",
      "units": "2, 25, 80, 13, 32, 49, 58, 51"
    },
    {
      "player": "noob",
      "ranking": "7290",
      "units": "27, 25, 64, 70, 37, 54, 57, 13"
    },
    {
      "player": "kng9976",
      "ranking": "7192",
      "units": "2, 25, 9, 51, 32, 54, 58, 11"
    },
    {
      "player": "SammoGay",
      "ranking": "7183",
      "units": "75, 1, 80, 73, 37, 49, 39, 14"
    },
    {
      "player": "Idonotexist",
      "ranking": "7183",
      "units": "34, 40, 68, 72, 86, 29, 17, 85"
    },
    {
      "player": "Adagramus",
      "ranking": "7117",
      "units": "42, 35, 17, 72, 77, 29, 68, 73"
    },
    {
      "player": "US Foreign Policy Dissenter",
      "ranking": "7056",
      "units": "42, 35, 9, 51, 52, 54, 58, 14"
    },
    {
      "player": "Atsou",
      "ranking": "7000",
      "units": "27, 25, 9, 51, 37, 29, 57, 14"
    }
  ]
}